import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  FeatureGrid,
  TeamsAccounts,
  TeamsRoles,
  TeamsCustomRoles,
  TeamsExport,
  TeamsWorkspaces,
  TeamsWorkspaceSwitcher
} from '../../components/FeatureGrid';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/teams/"
          title="Editorial Teams"
          description="Invite your entire content team to Affilimate."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            For content teams that need scalable, repeatable processes
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Whether you've got a full-blown team or just need to outsource a
            couple tasks, Affilimate's team features can help you scale and
            systematize your commerce content production.
          </Typography>
          <img
            src="/images/features/user-roles.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              borderRadius: '8px',
              boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
              transform: 'rotate(-2deg)',
              margin: '48px auto',
              display: 'block'
            }}
            alt="Affiliate team management"
          />
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Get started" />
          </div>
        </PageHead>
        <FeatureGrid>
          <TeamsAccounts />
          <TeamsRoles />
          <TeamsCustomRoles />
          <TeamsExport />
          <TeamsWorkspaces />
          <TeamsWorkspaceSwitcher />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
